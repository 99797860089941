<template>
  <div class="card rounded" style="width: 18rem;" @click.prevent="click">
    <img :src="data.room.cover" alt="..." class="card-img-top">
    <h5 class="card-title">{{ data.room.displayName }}
      <EqualizerIcon v-show="data.room.isPlaying"/>
    </h5>
    <router-link :to="link" class="play"><i class="fas fa-play"></i></router-link>
  </div>
</template>

<script>
import EqualizerIcon from "./EqualizerIcon"

export default {
  name: "RoomCard",
  components: {EqualizerIcon},
  props: {
    data: {
      type: Object
    }
  },
  methods: {
    click() {
      this.$router.push("/dashboard/listen/" + this.data.room.roomId);
    }
  },
  computed: {
    link() {
      return "/dashboard/listen/" + this.data.room.roomId
    }
  }
}
</script>

<style scoped>

.card {
  cursor: pointer;
  position: relative;
  text-align: center;

  border: none;

  -webkit-box-shadow: 3px 3px 21px -4px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 3px 3px 21px -4px rgba(0, 0, 0, 0.63);
  box-shadow: 3px 3px 21px -4px rgba(0, 0, 0, 0.63);

}

.card-title {
  position: absolute;
  bottom: 8px;
  left: 16px;

  color: white;
  font-size: 1.7em;
}

.play {
  position: absolute;
  bottom: 8px;
  right: 16px;

  font-size: 1.5em;
  margin: 0.5rem;
}


</style>