<template>
  <div class="card-group">
    <div v-for="room in sortedRooms" :key="room.roomId">
      <RoomCard :data="{ room }" class="roomCard"/>
    </div>
  </div>
</template>
<script>
import WebSocketAdapter from "@/websocket";
import RoomCard from "@/components/RoomCard";
import Room from "@/types/Room";

export default {
  name: "Overview",
  components: {RoomCard},
  data() {
    return {
      RoomList: [],
    }
  },
  created() {
    // noinspection JSUnresolvedFunction
    this.$parent.connect("/rooms"); // exists at "@/views/dashboard/Dashboard.vue"

    WebSocketAdapter.webSocket.onmessage = (message) => {
      console.log("Received message in " + this.$options.name + " saying: " + message.data)

      const roomList = JSON.parse(message.data);
      this.RoomList = roomList.rooms;
    }
  },
  computed: {
    sortedRooms() {
      return this.RoomList.sort((a, b) => {
        return (a.isPlaying - b.isPlaying)
      })
    }
  }
}
</script>

<style scoped>

.roomCard {
  margin: 1em;
}

</style>

