






export default {
  name: "Profile"
}
