<template>
  <div>
    <p>RoomID: {{ RoomData.roomId }}</p>
    <p>Cover: {{ RoomData.cover }}</p>
    <p>track: {{ RoomData.track }}</p>
    <p>uuid: {{ RoomData.uuid }}</p>
    <p>playing: {{ RoomData.isPlaying }}</p>
    <p>progress: {{ RoomData.progress }}</p>
  </div>
</template>

<script lang="js">
import WebSocketAdapter from "@/websocket";

export default {
  name: "Listen",
  data() {
    return {
      message: {},
      RoomData: {},
    }
  },
  methods: {
    async play(prog) {
      const device = await this.$store.getters.getSpotifyApi.getMyDevices().then(value => {
        let userDevice = value.devices.find(device => device.is_active);
        if (userDevice === undefined) {
          userDevice = value.devices[0];
        }
        return userDevice;
      }).catch(reason => {
        console.log(reason);
      })

      console.log("device: " + JSON.stringify(device))
      if (device === undefined) {
        console.log("no device found")
        return;
      }
      await this.$store.getters.getSpotifyApi.play({
        device_id: device.id,
        uris: [this.RoomData.track],
        position_ms: prog
      })

      // TODO: save one request cause in playback state are the devices https://developer.spotify.com/documentation/web-api/reference/player/get-information-about-the-users-current-playback/
      this.$store.getters.getSpotifyApi.getMyCurrentPlaybackState().then(value => {
        if (this.RoomData.isPlaying !== value.is_playing) {
          this.playpause();
        }
      });
    },
    async playpause() {
      if (this.RoomData.isPlaying) {
        await this.$store.getters.getSpotifyApi.play();
      } else {
        await this.$store.getters.getSpotifyApi.pause();
      }
    }
  },
  created() {
    // noinspection JSUnresolvedFunction
    this.$parent.connect("/listen/" + this.$route.params.roomId); // exists at "@/views/dashboard/Dashboard.vue"

    WebSocketAdapter.webSocket.onmessage = (message) => {
      console.log("Received message in " + this.$options.name + " saying: " + message.data)

      const msg = JSON.parse(message.data);
      this.message = msg;


      switch (msg.action) {
        case "welcome":
          this.RoomData = {
            roomId: this.$route.params.roomId,
            cover: msg.cover,
            track: msg.track,
            progress: msg.progress,
            uuid: msg.uuid,
            isPlaying: msg.playing,
          }
          this.play(this.RoomData.progress);
          break;
        case "songchange":
          this.RoomData = {
            ...this.RoomData, ...{
              cover: msg.cover,
              track: msg.track,
              progress: msg.progress,
              isPlaying: msg.playing,
            }
          }
          this.play();
          break;
        case "playpause":
          this.RoomData = {
            ...this.RoomData, ...{
              progress: msg.progress,
              isPlaying: msg.playing,
            }
          }
          this.playpause();
          break;
        case "progress": //TODO: sync
          this.RoomData = {
            ...this.RoomData, ...{
              progress: msg.progress,
            }
          }
          break;
      }
    }
  },
}
</script>

<style scoped>

</style>